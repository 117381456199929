/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --color: black;
  --background-color: #fff;
  --box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  --sidebar-left-margin: 20px;
  --menu-hover-color: rgb(246, 246, 246);
  --table-heading-background-color: #f2f2f2;
  --table-heading-font-weight: 600;
  --table-data-background-color: #FAFAFA;
  --profile-heading-color: rgb(153, 153, 153);


  // Landing Page colors
  --primary-color: #007BFF;
  --info-color: #909090;
  --color-black: #080808;
  --color-tertiary: #03BA0A;
  --color-white: #fff;
  --left-right-padding: 95px;
  --header-font-family: "Public Sans", "sans-serif";
  --header-font-weight: 500;
  --header-font-size: 15px;
  --body-font-family: "poppins", "sans-serif";
  --brand-name-color: #FF9500;



}

@import "primeicons/primeicons.css";
.message-container{
  max-height: 70vh; /* Set max-height to ensure overflow */
  min-height: 50vh;
  overflow-y: auto; /* Keep overflow to auto */
  margin: 0 20px;
}
.message-container::-webkit-scrollbar{
  display: none;
}
.message-header{
  display: flex;
  justify-content: space-between;

}
.header-content{
  display: flex;
  gap: 10px;
}

.brand-detail > p {
  margin: 0;
  padding: 0;
  color: #969DAB;
}
.timestamp{
  color: #969DAB;
  font-size: 10px;
}
.brand-detail > h5 {
  margin: 0;
  padding: 0
}

.message-body{
  margin-top: 10px;
}

.message-card{

}
.message-container{
  max-height: 70vh; /* Set max-height to ensure overflow */
  min-height: 50vh;
  overflow-y: auto; /* Keep overflow to auto */
  margin: 0 20px;
}
.message-container::-webkit-scrollbar{
  display: none;
}
.message-header{
  display: flex;
  justify-content: space-between;

}


// Dropzone 
/* Dropzone container */
.dz-preview {
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  gap: 20px;
  margin-top: 10px;
}

/* File details */
.dz-details {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;
}

.dz-filename {
  font-size: 14px;
  font-weight: 500;
  color: #374151;
}

.dz-size {
  font-size: 12px;
  color: #6b7280;
}

.dz-thumbnail {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  object-fit: cover;
  border: 1px solid #d1d5db;
}

/* Progress bar */
.dz-progress {
  width: 100%;
  height: 4px;
  background-color: #e5e7eb;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  margin-top: 8px;
}

.dz-upload {
  display: block;
  height: 100%;
  background-color: #3b82f6;
  transition: width 0.3s ease-in-out;
}

/* Success mark */
.dz-success-mark {
  display: none;

}
.dz-success-mark > svg {
  color: #10b981;
  font-size: 18px;
}


/* Error mark */
.dz-error-mark {
  display: none;

}
.dz-error-mark span {
  color: #ef4444;
  font-size: 18px;
}

/* Error message */
.dz-error-message {
  color: #ef4444;
  font-size: 12px;
  margin-top: 8px;
  display: none;
}

/* Show error message when there's an error */
.dz-error-message span[data-dz-errormessage] {
  display: block;
}

/* Hover effect on preview */
.dz-preview:hover {
  background-color: #f3f4f6;
}



.table-section{
  max-width: 80vw;
  overflow-x: auto;

}

// Old CSS
.content-wrapper {
  background-color: var(--background-color) !important;
}

.main-sidebar {
  top: 40px !important;
  left: var(--sidebar-left-margin) !important;
  border-radius: 20px;
}

// Table Styles 

.custom-table {
  border-radius: 10px;
  overflow: hidden;

}

.custom-table th {
  margin-top: 20px;
  background-color: var(--table-heading-background-color) !important;
  border: none;


}

.custom-table td {
  border: none;
  background-color: var(--table-data-background-color);
  border-top: 5px solid white;


}

.custom-table th:first-child {
  border-top-left-radius: 20px !important;
  // border-bottom-left-radius: 20px !important;
}

.custom-table th:last-child {
  border-top-right-radius: 20px !important;
  // border-bottom-right-radius: 20px !important;
}

.custom-table tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
  /* Bottom-right corner radius */
}

.custom-table tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
  /* Bottom-left corner radius */
}

.table-section {
  padding: 0 15px;
}

// Table End

.filter-section .search-input,
.input-section {
  border: none;
  outline: none;
  box-shadow: var(--box-shadow);
  padding: 10px 15px;
  border-radius: 20px;
  width: 100%;

}

.action-button {
  border: none;
  outline: none;
  box-shadow: var(--box-shadow);
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #FFF;
  font-weight: 500;
  font-size: 10px;
}

.reg-action-button {
  border: none;
  outline: none;
  box-shadow: var(--box-shadow);
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #FFF;
  font-weight: 500;
  font-size: 10px;
  width: 120px;
  background-color: black;
  color: white;
  text-transform: uppercase;
}

.reg-action-button:disabled {
  background-color: rgba(0, 0, 0, 0.207);
  cursor: not-allowed;


}

.action-button:focus {
  outline: none;
}

.dropdown-button {
  border: none;
  outline: none;
  box-shadow: var(--box-shadow);
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #FFF;
  font-weight: 500;
  font-size: 10px;
}

.dropdown-button:focus {
  outline: none;
}

.carousel-image {
  height: 250px;
  width: 250px;
  object-fit: cover;
  aspect-ratio: 2/3;

}

.custom-card {
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  padding: 20px;

}


.brand-logo {
  width: 100px;
  box-shadow: var(--box-shadow);
}

.input-cross-icon{
  position: absolute;
  right: 12px;
  top: 12px;
}

.message-card{

}
.message-container{
  max-height: 70vh; /* Set max-height to ensure overflow */
  min-height: 50vh;
  overflow-y: auto; /* Keep overflow to auto */
  margin: 0 20px;
}
.message-container::-webkit-scrollbar{
  display: none;
}
.message-header{
  display: flex;
  justify-content: space-between;

}
.header-content{
  display: flex;
  gap: 10px;
}

.brand-detail > p {
  margin: 0;
  padding: 0;
  color: #969DAB;
}
.timestamp{
  color: #969DAB;
  font-size: 10px;
}
.brand-detail > h5 {
  margin: 0;
  padding: 0
}

.message-body{
  margin-top: 10px;
}



.message-card .brand{

}

.p-avatar.p-avatar-circle{
  border-radius: 50%;
}

.del-icon{
  font-size: 20px;
  cursor: pointer;
}
.del-icon:hover{
 color: red;
}

.custom-badge{
  background-color: #FF9500;
  color: white;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 10px;

}

.layout-navbar-fixed.layout-fixed.text-sm .wrapper .sidebar::-webkit-scrollbar{
display: none;
}

.msg-badge{
  background-color: white;
  position: absolute;
  left: 60%;

}