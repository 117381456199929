.danger-btn {
  @apply py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-red-200 bg-white text-red-500 shadow-sm hover:bg-red-50 focus:outline-none focus:bg-red-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700;
}
.success-btn {
  @apply py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-teal-200 bg-white text-teal-500 shadow-sm hover:bg-teal-50 focus:outline-none focus:bg-teal-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700;
}

.primary-btn {
  @apply py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none;
}
.primary-input {
  @apply py-2 ps-10 pe-16 block  bg-white border-gray-200 rounded-lg text-sm focus:outline-none focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600;
}
.primary-filter{
    @apply py-3 px-4 block border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
}

.table-filter {
  @apply px-3 flex justify-between  mb-2 mt-3 flex-wrap gap-2 items-center;
}
@media (max-width: 768px) {
  .table-filter {
    @apply flex-col items-start;
  }
}
